import { ButtonColor, DialogActionButtonData, DialogActionConfig } from "./dialog-action-data.model";

export const getDialogActionButtons = (buttons?: DialogActionButtonData<unknown>[]) => {
  if (buttons?.length > 0) {
    return buttons;
  }
  return defaultButtons;
};

const defaultButtons: DialogActionButtonData<boolean>[] = [
  { label: "CANCEL", buttonColor: ButtonColor.Default, action: false },
  { label: "CONFIRM", buttonColor: ButtonColor.Primary, action: true },
];

export const getDialogActionConfig = (config?: DialogActionConfig) => {
  return {
    ...defaultConfig,
    ...config,
  };
};

const defaultConfig: DialogActionConfig = {
  width: "500px",
  closeOnNavigation: true,
  autoFocus: false,
};
