import { SafeHtml } from "@angular/platform-browser";

export interface DialogActionData<TAction = boolean> {
  body: string | SafeHtml;
  title?: string;
  buttons?: DialogActionButtonData<TAction>[];
  config?: DialogActionConfig;
}

export interface DialogActionButtonData<TAction = boolean> {
  label: string;
  buttonColor: ButtonColor;
  action: TAction;
}

export interface DialogActionConfig {
  width: string;
  closeOnNavigation: boolean;
  autoFocus: boolean;
}

export enum ButtonColor {
  Primary = "primary",
  Default = "",
}
