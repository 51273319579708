import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

export class StateService<T> {
	private state$: BehaviorSubject<T>;
	protected get state(): T {
		return this.state$.getValue();
	}

	public constructor(initialState: T) {
		this.initState(initialState);
	}

	protected select<K>(mapFn: (state: T) => K): Observable<K> {
		return this.state$.asObservable().pipe(
			map((state: T) => mapFn(state)),
			distinctUntilChanged()
		);
	}

	protected setState(newState: Partial<T>): void {
		this.state$.next({
			...this.state,
			...newState,
		});
	}

  protected initState(initialState: T = null): void {
		this.state$ = new BehaviorSubject<T>(initialState);
	}
}
